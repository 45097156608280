import React, { memo } from 'react'
import IconTabList from './IconTabList'
import IconTab from './IconTab'
import { TextField, ImageField, Item } from '../../types/SitecoreAdapter'

export type SitecoreTab = Item<{
  href: TextField
  text: TextField
  title: TextField
  icon: ImageField
}>

type IconTabsProps = {
  tabs: SitecoreTab[]
  styleOverride?: { iconTabList?: string }
}

export function IconTabs({ tabs, styleOverride }: IconTabsProps) {
  if (!tabs || tabs.length === 0) {
    return null
  }

  return <IconTabList styleOverride={styleOverride}>{tabs.map(adaptTab)}</IconTabList>
}

function adaptTab({ fields: { icon, href, text, title } }: SitecoreTab) {
  const iconProps =
    icon.value && icon.value.src
      ? { icon: <img src={icon.value.src} alt={icon.value.alt} /> }
      : {}

  return (
    <IconTab
      key={href.value}
      href={href.value}
      title={title.value}
      {...iconProps}
    >
      {text.value}
    </IconTab>
  )
}

export default memo(IconTabs)
