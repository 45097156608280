import React, { Suspense } from 'react'
import loadable from '@loadable/component'

import { Placeholder, RouteData } from '@sitecore-jss/sitecore-jss-react'
import { BooleanField, Item } from '../../types/SitecoreAdapter'

import Nav from '../../page-components/Navigation'
import Banner from '../../components/Banner'
import Hero, { HeroProps } from '../../page-components/Hero'
import HeroAppStartSwitch from '../../quotes/HeroAppStartSwitch'
import CTABanner, { ICTABannerProps } from '../../components/CTA-Banner'

import ScrollToTopOnMount from '../../util/ScrollToTopOnMount'
import { ICTABannerEscapeText } from '../../page-components/getEscapeText'
import BreadcrumbsFromRoute from '../../page-components/Breadcrumb/BreadcrumbsFromRoute'
import BreadcrumbList from '../../page-components/Breadcrumb/BreadcrumbList'
import styles from './styles.module.scss'
import { get } from 'lodash-es'
import Section from '../../components/Section/Section'

const CTAAppStartSwitch = loadable(
  () => import('../../quotes/CTAAppStartSwitch')
)
const TestMetadata = loadable(() => import('../../components/Test-Metadata'))

type MajorPageProps = {
  route: {
    fields: {
      hero: Item<HeroProps>
      ctaBanner: {
        fields: ICTABannerProps
      } & ICTABannerEscapeText
      tpWidgetGlobalSettings: {
        fields: {
          enableTrustPilotHeroWidget: BooleanField
        }
      }
    }
  } & RouteData
}

const MajorPage = ({ route }: MajorPageProps) => {
  const { ctaBanner, hero } = route.fields
  const { enableTrustPilotHeroWidget } =
    route.fields.tpWidgetGlobalSettings.fields

  const heroProps = {
    ...hero?.fields,
    applyDefaultImgSizing: true,
    enableTrustPilotHeroWidget,
  }

  return (
    <>
      <Nav />
      <Banner />
      <Suspense>
        <div className="major copy">
          <TestMetadata pageType="Major" appStartType="Typeahead" />
          <ScrollToTopOnMount />

          <Hero {...heroProps}>
            <HeroAppStartSwitch
              darkBg={get(hero, 'fields.isDark.value', false)}
            />
          </Hero>

          <Placeholder name="Hero-Placeholder" rendering={route} />

          <BreadcrumbsFromRoute>
            {(breadcrumbs) => (
              <BreadcrumbList
                showWrap={false}
                breadcrumbs={breadcrumbs}
                isSticky={false}
                paddedLeft
                className={styles.breadcrumb}
              />
            )}
          </BreadcrumbsFromRoute>

          <Placeholder name="Major-Placeholder" rendering={route} />

          {ctaBanner && (
            <Section>
              <CTABanner testId="MajorPage-CTABanner" {...ctaBanner.fields}>
                <CTAAppStartSwitch />
              </CTABanner>
            </Section>
          )}
        </div>
      </Suspense>
    </>
  )
}

export default MajorPage
