import React, { ReactNode } from 'react'
import Button from '../Button'
import LayoutWrapper, { ILayoutWrapperProps } from './LayoutWrapper'
import clsx from 'clsx'

export interface ICMSButtonBase {
  primary: boolean
  children: ReactNode
  buttonClassName?: string
  testId?: string
}

type CMSButtonProps = {
  title: string
  href: string
  newWindow?: boolean
} & ICMSButtonBase &
  ILayoutWrapperProps

type ButtonAttributes = {
  target?: string
}

const CMSButton = ({
  title,
  href,
  primary,
  alignment,
  size,
  newWindow,
  wrapClassName,
  buttonClassName,
  testId,
  children,
}: CMSButtonProps) => {
  const attrs: ButtonAttributes = {}

  if (newWindow) {
    attrs.target = '_blank'
  }

  return (
    <LayoutWrapper
      alignment={alignment}
      size={size}
      wrapClassName={clsx("CMS-Button", wrapClassName)}
    >
      <Button
        className={buttonClassName}
        primary={primary}
        secondary={!primary}
        title={title}
        size={size}
        href={href}
        testId={testId}
        {...attrs}
      >
        {children}
      </Button>
    </LayoutWrapper>
  )
}

export default React.memo(CMSButton)
