import React, { ReactNode } from 'react'
import clsx from 'clsx'
import spaceStyles from '../../styles/space.module.scss'
import styles from './styles.module.scss'

type IconTabListProps = {
  children: ReactNode
  styleOverride?: { iconTabList?: string }
}

function IconTabList({ children, styleOverride }: IconTabListProps) {
  return (
    <div
      className={clsx(
        styles.list,
        spaceStyles.contain,
        styleOverride?.iconTabList
      )}
    >
      {children}
    </div>
  )
}

export default IconTabList
