import React, { useContext } from 'react'
import clsx from 'clsx'

import Button from '../../Button'
import Video from '../../Video/Video'
import {
  getIdFromUrl,
  getEmbedUrlFromId,
} from '../../Video/services/getEmbedUrl'

import { ModalCTAContext } from '../../../context/ModalCTAContext'
import CTALinkIntercept from '../../../quotes/CTALinkIntercept'
import CollapsiblePanelBase from '../../CollapsiblePanel/base'
import { SingleColumnVideoProps } from '../types'

const SingleColumnVideo = ({
  heading,
  descriptor,
  videoUrl,
  ctaButtonText,
  isBottomDescriptor,
  headingSize,
  useBodyText,
  styles,
  transcript,
  lazyLoad = true,
}: SingleColumnVideoProps) => {
  const { showModal } = useContext(ModalCTAContext)
  const videoId = getIdFromUrl(videoUrl) || ''
  const videoEmbedUrl = videoId ? getEmbedUrlFromId(videoId) : ''
  const descriptorStyleClass = useBodyText ? 'BodyText' : styles.descriptor

  const descElement = useBodyText ? (
    <CTALinkIntercept>{descriptor}</CTALinkIntercept>
  ) : (
    descriptor
  )

  return (
    <div className={clsx(styles.singleColumnVideo, 'SingleColumnVideo')}>
      {!!heading && (
        <>
          {!!headingSize && headingSize === 'H3' ? (
            <h3 className={clsx(styles.header)}>{heading}</h3>
          ) : (
            <h2 className={clsx(styles.header)}>{heading}</h2>
          )}
        </>
      )}
      {descriptor && !isBottomDescriptor && (
        <div className={clsx(styles.topDescriptor, descriptorStyleClass)}>
          {descElement}
        </div>
      )}
      <div className={styles.videoComponent}>
        <Video id={videoId} embedUrl={videoEmbedUrl} lazyLoad={lazyLoad} />
        {transcript && (
          <div className={styles.transcriptContainer}>
            <CollapsiblePanelBase
              testId="SingleColumnVideo-TranscriptPanel"
              toggle={
                <span className={styles.titleHeading}>
                  View video transcript.
                </span>
              }
              open={false}
              styleOverrides={{ body: styles.body, toggle: styles.toggle }}
              isVideoAccordion
            >
              {transcript}
            </CollapsiblePanelBase>
          </div>
        )}
      </div>
      {descriptor && isBottomDescriptor && (
        <div className={clsx(styles.bottomDescriptor, descriptorStyleClass)}>
          {descElement}
        </div>
      )}
      {ctaButtonText && (
        <div className={styles.ctaButtonComponent}>
          <Button
            testId="SingleColumnVideo-CTABtn"
            className={clsx('button', styles.ctaButton)}
            type="primary"
            onClick={showModal}
          >
            {ctaButtonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default SingleColumnVideo
