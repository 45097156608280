import React, { useContext } from 'react'
import clsx from 'clsx'

import Button from '../../Button'
import Video from '../../Video/Video'
import {
  getIdFromUrl,
  getEmbedUrlFromId,
} from '../../Video/services/getEmbedUrl'

import { ModalCTAContext } from '../../../context/ModalCTAContext'
import CollapsiblePanelBase from '../../CollapsiblePanel/base'
import { MultiColumnVideoProps } from '../types'

const MultiColumnVideo = ({
  heading,
  descriptor,
  videoUrl,
  ctaButtonText,
  alignment,
  headingSize,
  useBodyText,
  styles,
  transcript,
  lazyLoad = true,
}: MultiColumnVideoProps) => {
  const { showModal } = useContext(ModalCTAContext)
  const videoId = getIdFromUrl(videoUrl) || ''
  const videoEmbedUrl = videoId ? getEmbedUrlFromId(videoId) : ''
  const descriptorStyleClass = useBodyText
    ? 'BodyText'
    : styles.multiColDescriptor

  const descriptorCtaEl = (
    <div className={styles.descCtaComponent}>
      {descriptor && <div className={descriptorStyleClass}>{descriptor}</div>}
      {ctaButtonText && (
        <div className={styles.multiColCtaButtonComponent}>
          <Button
            testId="MultiColumnVideo-CTABtn"
            className={clsx('button', styles.ctaButton)}
            type="primary"
            onClick={showModal}
          >
            {ctaButtonText}
          </Button>
        </div>
      )}
    </div>
  )

  const videoEl = (
    <div className={styles.multiColVideoComponent}>
      <Video id={videoId} embedUrl={videoEmbedUrl} lazyLoad={lazyLoad} />
      {transcript && (
        <div className={styles.transcriptContainer}>
          <CollapsiblePanelBase
            testId="MultiColumnVideo-TranscriptPanel"
            toggle={
              <span className={styles.titleHeading}>
                View video transcript.
              </span>
            }
            open={false}
            styleOverrides={{ body: styles.body, toggle: styles.toggle }}
            isVideoAccordion
          >
            {transcript}
          </CollapsiblePanelBase>
        </div>
      )}
    </div>
  )

  return (
    <div className={clsx(styles.multiColumnVideo, "MultiColumnVideo")}>
      {heading && headingSize === 'H3' ? (
        <h3 className={clsx(styles.header)}>{heading}</h3>
      ) : (
        <h2 className={clsx(styles.header)}>{heading}</h2>
      )}
      <div className={styles.columns}>
        <div className={styles.leftColumn}>
          {alignment === 'right' ? descriptorCtaEl : videoEl}
        </div>
        <div className={styles.rightColumn}>
          {alignment === 'left' ? descriptorCtaEl : videoEl}
        </div>
      </div>
    </div>
  )
}

export default MultiColumnVideo
