import React, { useContext } from 'react'
import clsx from 'clsx'
import { RichText } from '@sitecore-jss/sitecore-jss-react'

import SingleColumnVideo from './variants/SingleColumnVideo'
import MultiColumnVideo from './variants/MultiColumnVideo'

import PageTypography from '../PageTypography'
import Media, { CurrentBreakpoint } from '../Media'

import articleStyles from './styles/styles-article.module.scss'
import copyStyles from './styles/styles-copy.module.scss'
import homePageStyles from './styles/styles-home.module.scss'
import { VideoComponentProps } from './types'
import withErrorBoundary from '../../util/components/ErrorBoundary/withErrorBoundary'

const VideoComponent = ({ fields }: VideoComponentProps) => {
  const descriptorElCheck = fields.descriptor && fields.descriptor.value !== ''
  const transcriptElCheck =
    fields.videoTranscript && fields.videoTranscript.value !== ''
  const currentBreakpoint = useContext(CurrentBreakpoint)

  const getPageStyles = (pageType: string) => {
    if (pageType === 'blog' && currentBreakpoint === 'mobile') {
      return 'block'
    } else if (pageType !== 'home') {
      return 'contain block'
    }

    return ''
  }

  const DesktopComponent = () => {
    return (
      <>
        {fields.variant && fields.variant.value === 'MultiCol' ? (
          <div
            data-test-id={'VideoComponent-Wrap'}
            className={clsx(
              copyStyles.multiColumnVideoContainer,
              'MultiColumnVideoContainer'
            )}
          >
            <MultiColumnVideo
              heading={fields.heading.value}
              headingSize={fields.headingSize && fields.headingSize.value}
              descriptor={<RichText field={fields.descriptor} />}
              videoUrl={fields.videoUrl.value}
              ctaButtonText={fields.ctaButtonText.value}
              alignment={fields.alignment && fields.alignment.value}
              styles={copyStyles}
              useBodyText={fields.useBodyText && fields.useBodyText.value}
              transcript={
                (transcriptElCheck && (
                  <RichText field={fields.videoTranscript} />
                )) ||
                null
              }
              lazyLoad={fields.lazyLoad && fields.lazyLoad.value}
            />
          </div>
        ) : (
          <PageTypography>
            {(pageType: string) => (
              <div
                data-test-id={'VideoComponent-Wrap'}
                className={clsx(
                  getPageStyles(pageType),
                  articleStyles.singleColumnVideoContainer,
                  'SingleColumnVideoContainer'
                )}
              >
                <SingleColumnVideo
                  heading={fields.heading.value}
                  headingSize={fields.headingSize && fields.headingSize.value}
                  {...(descriptorElCheck
                    ? {
                        descriptor: <RichText field={fields.descriptor} />,
                      }
                    : {})}
                  videoUrl={fields.videoUrl.value}
                  ctaButtonText={fields.ctaButtonText.value}
                  isBottomDescriptor={fields.isBottomDescriptor.value}
                  styles={
                    pageType === 'home'
                      ? homePageStyles
                      : pageType === 'article' ||
                        pageType === 'policy' ||
                        pageType === 'blog'
                      ? articleStyles
                      : copyStyles
                  }
                  useBodyText={fields.useBodyText && fields.useBodyText.value}
                  transcript={
                    (transcriptElCheck && (
                      <RichText field={fields.videoTranscript} />
                    )) ||
                    null
                  }
                  lazyLoad={fields.lazyLoad && fields.lazyLoad.value}
                />
              </div>
            )}
          </PageTypography>
        )}
      </>
    )
  }

  const MobileComponent = () => {
    return (
      <PageTypography>
        {(pageType: string) => (
          <div
            data-test-id={'VideoComponent-Wrap'}
            className={clsx(
              getPageStyles(pageType),
              articleStyles.singleColumnVideoContainer
            )}
          >
            <SingleColumnVideo
              heading={fields.heading.value}
              headingSize={fields.headingSize && fields.headingSize.value}
              {...(descriptorElCheck
                ? {
                    descriptor: <RichText field={fields.descriptor} />,
                  }
                : {})}
              videoUrl={fields.videoUrl.value}
              ctaButtonText={fields.ctaButtonText.value}
              isBottomDescriptor={
                fields.variant && fields.variant.value === 'MultiCol'
                  ? true
                  : fields.isBottomDescriptor.value
              }
              styles={
                pageType === 'home'
                  ? homePageStyles
                  : pageType === 'article' ||
                    pageType === 'policy' ||
                    pageType === 'blog'
                  ? articleStyles
                  : copyStyles
              }
              useBodyText={fields.useBodyText && fields.useBodyText.value}
              transcript={
                (transcriptElCheck && (
                  <RichText field={fields.videoTranscript} />
                )) ||
                null
              }
              lazyLoad={fields.lazyLoad && fields.lazyLoad.value}
            />
          </div>
        )}
      </PageTypography>
    )
  }

  return (
    <Media
      desktop={() => <DesktopComponent />}
      laptop={() => <DesktopComponent />}
      mobile={() => <MobileComponent />}
    />
  )
}

export default withErrorBoundary(VideoComponent, {
  component: 'VideoComponent',
})
